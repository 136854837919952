<template>
  <div class="voucher-wrapper" :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    <div v-if="!voucherSuccess" class="voucher-container">
      <div class="voucher-logo">
        <!-- <img src="@/assets/icons/voucherlogo.svg" alt="Noor Play Logo" /> -->
      </div>
      <h1 class="voucher-tittle">{{ $t(title) }}</h1>
      <!-- <p>{{ subtitle }}</p> -->
      <div class="voucher-input-group">
        <label for="voucher-code">{{ $t(label) }}</label>
        <div dir="ltr" class="mobile-input-container">
          <!-- Country Code Box -->
          <div v-if="country_code" class="country-code-box">
            {{ country_code }}
          </div>

          <!-- Mobile Number Input Box -->
          <input
            type="tel"
            class="mobile-number-input"
            :class="{ 'rtl-input': localDisplayLang === 'ara' }"
            v-model="mobileNumber"
            maxlength="10"
            @input="validateInput"
            :placeholder="localDisplayLang === 'ara' ? 'أدخل رقم هاتفك المحمول' : 'Enter your mobile number'"
          />
        </div>

        <!-- Error message displayed if errorMsg is not empty -->
        <p v-if="errorMsg" class="voucher-error">{{ errorMsg }}</p>
      </div>
      <!-- <button class="voucher-btn" @click="verifySubscriptionToken">
        {{ $t(buttonText) }}
      </button> -->
     <!-- Button with Loading Dots -->
      <button class="voucher-btn" @click="verifySubscriptionToken" :disabled="isLoading">
        <span v-if="isLoading" class="loading-dots">
          <span>. </span><span>. </span><span>. </span>
        </span>
        <span v-else>{{ $t(buttonText) }}</span>
      </button>
    </div>

    <!-- <payment :couponCode="couponCode" :paymentDetail="paymentDetail" :closePopup="() => closePopup()" v-else> </payment> -->
    <SyncpaymentSuccess v-else />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { eventBus } from "@/eventBus";
import SyncpaymentSuccess from "@/components/Templates/SyncpaymentSuccess.vue";
import payment from "@/components/Popups/Payment/Payment.vue";

export default {
  components: {
    SyncpaymentSuccess,
    payment
  },
  data() {
    return {
      title: "Subscription Activation",
      subtitle: "Mobile NO",
      label: "To confirm your subscription, Pleaese enter the mobile number used for payment",
      buttonText: "Submit",
      voucherCode: "",
      // inputPlaceholder: "Enter your code here...",
      errorMsg: "", // Error messages
      voucherSuccess: false, // Voucher success state
      planName: "Monthly Plan",
      planExpireDate: null,
      localDisplayLang: null,
       country_code: "",
        mobileNumber: "", // Stores the mobile number entered by the user
        subscriptionToken: "",
        isLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      "appConfig",
      "subscriberid",
      "availabilityList",
      "subscriptionList",
    ]),
    backgroundImage() {
      if (this.localDisplayLang === "eng") {
        return this.isMobileDevice 
          ? require("@/assets/icons/english-mobile-background.jpg") 
          : require('@/assets/icons/english-large-background.jpg');
      } else if (this.localDisplayLang === "ara") {
        return this.isMobileDevice 
          ? require('@/assets/icons/arabic-mobile-background.jpg') 
          : require('@/assets/icons/arabic-large-background.jpg');
      }
      return '';
    },
    isMobileDevice() {
      return window.innerWidth <= 768; // Adjust this value based on your needs
    }
  },
  created() {
    this.country_code = "+966";
    this.getSubscriptionTokenFromURL();
    //alert("called createddd landing pageee")
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
   
  },
  mounted() {
  },

  methods: {
    ...mapActions(["subscriptionSync"]),
    ...mapMutations(["setPlanDeeplinkPath",  "setNewSubscription"]),
    getSubscriptionTokenFromURL() {
      const urlParams = new URLSearchParams(window.location.search);
      this.subscriptionToken = urlParams.get("subscriptiontoken") || "";
    },
    validateInput() {
        // Remove non-numeric characters
        this.mobileNumber = this.mobileNumber.replace(/\D/g, "");
         // Ensure input is only numbers and max length is 10
    this.mobileNumber = this.mobileNumber.toString().slice(0, 10);

      
    },
async verifySubscriptionToken() {
      // Prevent multiple clicks
      if (this.isLoading) return;
      // Validate mobile number
      if (!this.mobileNumber.trim()) {
        this.errorMsg = "Please enter a mobile number."
        return;
      }
      if (this.mobileNumber.length < 8) {
        this.errorMsg = "Mobile number must be at least 8 digits.";
        return;
      }
      this.errorMsg = ""; // Clear any previous error
       this.isLoading = true; // Start loading
      
      const requestData = {
        mobileno: this.country_code + this.mobileNumber, 
        subscriptiontoken: this.subscriptionToken, // Use token from URL
      };
      this.subscriptionSync(requestData).then((response) => {
        console.log("subscriptionSyncresp",response)
        if (response && response.data  && response.data.reason) {
            this.errorMsg = response.data.reason;
            this.isLoading = false; // Stop loading
        }else {
        this.voucherSuccess = true;
        this.isLoading = false; // Stop loading
        }
      }).catch((error) => {
        this.errorMsg = "An error occurred. Please try again.";
         this.isLoading = false; // 
      })
    },
   
 
    },

};
</script>

<style scoped>

/* Remove white background (for Safari) */
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: textfield;
  background-color: transparent; /* Keeps it consistent */
  color: black; /* Adjust text color if needed */
  border: 1px solid #ccc; /* Optional: Style border */
  padding: 8px;
  border-radius: 5px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove spinner in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove default number spinner (Chrome, Safari, Edge, Opera) */
.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove spinner for Firefox */
.no-spinner {
  -moz-appearance: textfield;
}
   .mobile-input-container {
  display: flex;
  align-items: center;

  border-radius: 8px;
  overflow: hidden;
  width: 100%;
 
}

.country-code-box {
 padding: 8.5px 12px;
  border-right: 1px solid #ccc;
  border: 1px solid #3a3a3c;;
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
}

.mobile-number-input {
  flex: 1;
  padding: 10px;
  border: none;
  outline: none;
}
.voucher-btn {
  background-color: #560C1D;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
}

/* Disable button */
/* .voucher-btn:disabled {
  background-color: grey;
  cursor: not-allowed;
} */

/* Loading dots */
.loading-dots span {
  animation: loadingDots 1.5s infinite;
  font-size: 18px;
  font-weight: bold;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loadingDots {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.rtl-input {
  text-align: right;
}


.voucher-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
  background: #191919;
 /* background-image: url("~@/assets/icons/mob_View_Background.png");*/
  /* background-image: url('~@/assets/icons/Oman_logo_ramadan_en.jpg'); Replace with your image path */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}
.voucher-container {
  background-color: rgba(30, 30, 30, 0.9); /* Add transparency for better visibility */
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #ffffff;
  max-width: 90%;
  width: 400px;
}
.voucher-logo {
  margin-bottom: 1rem;
}
.voucher-logo img {
  width: 100px;
  height: 100px;
}
.voucher-tittle{
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
  font-size: 1.5rem;
  margin-bottom: 3rem;
}
p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #FFFFFFB2;
}
.voucher-input-group {
  margin-bottom: 1.5rem;
}
.voucher-input-group label {
  display: flex;
  text-align: start;
  font-size: 16px;
  color: #8e8e93;
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.voucher-input-group input {
  width: 100%;
  padding: 0.625rem;
  border: 1px solid #3a3a3c;
  font-size: 14px;
    font-family: Open Sans, sans-serif, DroidArabicKufiRegular;
  
  color: #ffffff;
}
.voucher-btn {
  border: 2px solid #560C1D;
  background: #1d1d1d;
  padding: 0.625rem;
  width: 100%;
  border-radius: 0.5rem;
  color: #560C1D;
  font-size: 1rem;
  font-weight: 600;
  line-height: 15px;
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
}
.voucher-btn:hover {
  background-color: #560C1D;
  color: #ffffff;
}
.voucher-error {
  color: red; /* Red color for error messages */
  font-size: 0.875rem;
  margin-top: 0.5rem;
  text-align: left;
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
}
</style>
