<template>
  <div class="sync-payment-container">
    <div class="sync-payment-logo">
      <img src="@/assets/icons/success-logo.svg" alt="Success Logo" />
    </div>
    <h1 class="sync-payment-text">
      {{$t("Subscription successfully verified!")}}
      <br />
    </h1>
    <!-- <p class="sync-payment-plan">Plan Name: {{ planName }}</p>
    <p class="sync-payment-expiry">Plan Expire Date: {{ planExpireDate }}</p> -->
    <button @click="navigateHome" class="sync-payment-button">
      {{$t("Start Watching Now")}}
    </button>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapMutations } from "vuex";

export default {
  props: {
    planName: {
      type: String,
    },
    planExpireDate: {
      type: String,
    },
  },
  data() {
    return {
      autoRedirectTimer: null,
    };
  },
  mounted() {
    //this.startAutoRedirect(); // Start auto-redirect timer
  },
  methods: {
    ...mapMutations(["setPlanDeeplinkPath"]),
    navigateHome() {
     // this.clearAutoRedirect(); // Clear the timer
      //eventBus.$emit("toggleSuccessMessagePopupClose", false);
      this.$router.push({ name: "Home" });
      eventBus.$emit("rerender-menus");
      // sessionStorage.removeItem("voucherCode");
      // sessionStorage.removeItem("setPlanDeeplinkPath");
      // this.setPlanDeeplinkPath(null);
    },
    startAutoRedirect() {
      this.autoRedirectTimer = setTimeout(() => {
        this.navigateHome(); // Auto redirect
      }, 5000); // 5 seconds
    },
    clearAutoRedirect() {
      if (this.autoRedirectTimer) {
        clearTimeout(this.autoRedirectTimer);
        this.autoRedirectTimer = null;
      }
    },
  },
  beforeDestroy() {
   // this.clearAutoRedirect(); // Clear timer on destroy
  },
};
</script>

<style scoped>
.sync-payment-container {
  background-color: #1E1E1E;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #ffffff;
  max-width: 90%;
  width: 400px;
}

.sync-payment-logo {
  margin-bottom: 20px;
}

.sync-payment-text {
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
  font-size: 1.3rem;
  font-weight: 700;
  padding: 1rem;
}

.sync-payment-plan,
.sync-payment-expiry {
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
  font-size: 16px;
  margin: 5px 0;
  color: #656565;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
}

.sync-payment-button {
  font-family: "Open Sans", sans-serif, DroidArabicKufiRegular;
  display: inline-block;
  background-color: #560C1D;
  color: white;
  font-weight: 600;
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 30px;
  text-decoration: none;
}
</style>
