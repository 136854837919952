<template>
  <div class="landing_page_container">
    <div class="gradient">
      <div
        class="landing_page_container_planlist_container"
        :dir="localDisplayLang == 'ara' ? 'rtl' : 'ltr'"
      >
        <div class="container landing_page_container_planlist_container_card">
          <div
            v-if="loader"
            class="
              container
              landing_page_container_planlist_container_card_loader
            "
          >
            <Loading></Loading>
          </div>
          <div
            v-else-if="!loader && planList.length === 0"
            class="landing_page_container_planlist_container_card_no_plans"
          >
            <p>{{ $t("No Plans Available") }}</p>
          </div>
          <div
            class="landing_page_container_planlist_container_card_item"
            v-else
          >
            <div
              class="
                landing_page_container_planlist_container_card_item_plan_container
              "
            >
              <component
                :is="componentModule"
                :planList="planList"
                :selectedGateway="selectedGateway"
                @redirect="redirectFromLanding()"
              ></component>
            </div>
            <div
              class="
                landing_page_container_planlist_container_card_item_poster_container
              "
            >
              <img
                :src="getPoster()"
                :class="[
                  checkPartner
                    ? 'image_class_additional'
                    : 'image_class_regular',
                ]"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="container landing_page_container_suits_everyone"
        :dir="localDisplayLang == 'ara' ? 'rtl' : 'ltr'"
      >
        <p class="landing_page_container_suits_everyone_title">
          {{ $t("noor play suits everyone") }}
        </p>
        <div class="landing_page_container_suits_everyone_item_container">
          <div
            class="landing_page_container_suits_everyone_item_container_item"
            v-for="(item, index) in suitsEveryone"
            :key="index"
          >
            <img :src="item.poster" />
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
      <!-- <div class="linear_gradient"></div> -->
      <div
        class="landing_page_container_special_stories"
        :dir="localDisplayLang == 'ara' ? 'ltr' : ''"
      >
        <p class="landing_page_container_special_stories_title">
          {{ $t("noorplay special stories") }}
        </p>
        <div
          class="
            container
            landing_page_container_special_stories_item_container
          "
        >
          <div
            :id="`slider_controls`"
            class="
              landing_page_container_special_stories_item_container_slider_controls
            "
            :dir="localDisplayLang === 'ara' ? 'ltr' : ''"
          >
            <!-- previous-arrow -->
            <button type="button" class="feature-prev">
              <img
                src="@/assets/icons/less-than-filled-50.png"
                width="30px"
                alt="prev"
              />
            </button>
            <!-- next-arrow -->
            <button type="button" class="feature-forward">
              <img
                src="@/assets/icons/more-than-filled-50.png"
                width="30px"
                alt="for"
              />
            </button>
          </div>
          <div
            id="slider_carousel"
            :dir="localDisplayLang == 'ara' ? 'rtl' : 'ltr'"
          >
            <div
              class="landing_page_container_special_stories_item_container_item"
              v-for="(item, index) in specialStories"
              :key="index"
            >
              <div
                class="
                  landing_page_container_special_stories_item_container_item_postercard
                "
              >
                <!-- <div class="landing_page_container_special_stories_item_container_item_postercard_play">
                                    <img src="@/assets/icons/Triangle 1.svg" alt="">
                                </div> -->
                <div
                  class="
                    landing_page_container_special_stories_item_container_item_postercard_item
                  "
                >
                  <img
                    :src="
                      localDisplayLang == 'ara' ? item.poster_ar : item.poster
                    "
                    alt=""
                  />
                </div>
              </div>
              <div
                class="
                  landing_page_container_special_stories_item_container_item_title
                "
              >
                <p>
                  {{ localDisplayLang == "ara" ? item.title_ar : item.title }}
                </p>
              </div>
              <div
                class="
                  landing_page_container_special_stories_item_container_item_description
                "
              >
                <p>
                  {{
                    localDisplayLang == "ara"
                      ? item.description_ar
                      : item.description
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="landing_page_container_how_to_subscribe"
      :dir="localDisplayLang == 'ara' ? 'rtl' : 'ltr'"
    >
      <div class="landing_page_container_how_to_subscribe_container">
        <p class="landing_page_container_how_to_subscribe_container_title">
          {{ $t("how to subscribe ?") }}
        </p>
        <div
          class="
            landing_page_container_how_to_subscribe_container_item_container
          "
        >
          <div
            class="
              landing_page_container_how_to_subscribe_container_item_container_item
            "
            v-for="(item, index) in howToSubcribe"
            :key="index"
          >
            <img :src="item.poster" />
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="landing_page_container_subscribenow">
      <button @click="subscribeNow()">{{ $t("Subscribe NOW!") }}</button>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters, mapActions } from "vuex";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import { tns } from "tiny-slider/src/tiny-slider";
import { encryptDeviceInfo } from "@/utilities.js";
import { store } from "@/store/store";

export default {
  data() {
    return {
      suitsEveryone: [
        {
          poster: require("@/assets/icons/safe_content.svg"),
          title: this.$t("safe content"),
        },
        {
          poster: require("@/assets/icons/watch_offline.svg"),
          title: this.$t("watch offline"),
        },
        {
          poster: require("@/assets/icons/without_ads.svg"),
          title: this.$t("without ads"),
        },
        {
          poster: require("@/assets/icons/smart_tv_app.svg"),
          title: this.$t("smart tv app"),
        },
        {
          poster: require("@/assets/icons/multi_devices.svg"),
          title: this.$t("multi-devices"),
        },
      ],
      specialStories: [
        {
          poster:
            "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/liti1lnez9o_LANDSCAPE_THUMBNAIL.jpg",
          title: "The Ottoman",
          description:
            "A historical epic. One of the largest Turkish productions, the biography of the founder of the Ottoman Empire, Othman bin Ertugrul. His struggle against enemies, and his confrontation against the weakness and the betrayals, until the establishment of the State.",
          poster_ar:
            "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/6jfxnx4dwqc_LANDSCAPE_THUMBNAIL.jpg",
          title_ar: "المؤسس عثمان",
          description_ar:
            "في ملحمة تاريخية من أضخم الإنتاجات التركية، سيرة المؤسس عثمان بن أرطغرل وجهاده ومحاربوه ضد مغول الشرق وبيزنطيي الغرب ومواجهته لضعف الأمة وخيانات الأقربين، حتى تأسيس الدولة.",
        },
        {
          poster:
            "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/4qifvsx1lu8_LANDSCAPE_THUMBNAIL.jpg",
          title: "I am Jalaluddin",
          description:
            "The fearless, Jalal al-Din Mankabarti ibn Khwarizm Shah, the lion hunter, facing Genghis Khan and the Mongols on one hand and the conspiracies of the Assassins and the internal problems of the Khwarizm state on the other hand.",
          poster_ar:
            "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/lgl74rl8q3d_LANDSCAPE_THUMBNAIL.jpg",
          title_ar: "جلال الدين الخوارزمي",
          description_ar:
            "الشجاع جلال الدين منكبرتي بن خوارزم شاه صائد الأسود في مواجهة جنكيز خان والمغول ومؤامرات الحشاشين والمشاكل الداخلية في الدولة الخوارزمية.",
        },
        {
          poster:
            "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/aabhmsop1ul_LANDSCAPE_THUMBNAIL.jpg",
          title: "Keiki",
          description:
            "The people of Kazakhstan face a crisis of fate with the fall of the Russian Tsarism. They struggle for their freedom and are subjected to the brutality of the Bolshevik military. The wolf of the valley 'Kiki', also known as ,the hunter confronts the weakness of the leaders and the betrayal of some of them.",
          poster_ar:
            "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/zb9y1220yja_LANDSCAPE_THUMBNAIL.jpg",
          title_ar: "كيكي",
          description_ar:
            "يواجه شعب كازاخستان أزمة مصير مع سقوط القيصرية الروسية، ويناضل من أجل حريته ويتعرض لبطش العسكرية البلشفية، ويتصدى ذئب الوادي 'كيكي' الصياد لضعف القادة وخيانة بعضهم.",
        },
        {
          poster:
            "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/51wd6dwzbpm_LANDSCAPE_THUMBNAIL.jpg",
          title: "Sword of Justice",
          description:
            "The actor Emre Kevelgem plays the role of the Sword of Justice. One of the warriors of the commando squad of Sultan Mehmed the Conqueror in the golden phase of the Ottoman military. They are entrusted with the task of confronting the evil plans of King Lazar.",
          poster_ar:
            "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/9izzszbp26_LANDSCAPE_THUMBNAIL.jpg",
          title_ar: "سيف العدالة",
          description_ar:
            "يجسد الممثل ايمره كيفيلجيم دور سيف العدالة أحد محاربي فرقة المغاوير التابعة للسلطان محمد الفاتح في المرحلة الذهبية للعسكرية العثمانية، حيث توكل إليهم مهمة التصدي لمخططات الملك لازار الشريرة.",
        },
        {
          poster:
            "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/8ykxo0qy5s3_LANDSCAPE_THUMBNAIL.jpg",
          title: "Miracle In Cell No. 7",
          description:
            "After being separated from his daughter, Mimo, who has a mental disability, struggles to prove his innocence of a crime he didn't commit.",
          poster_ar:
            "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/fboqdu7d1bu_LANDSCAPE_THUMBNAIL.jpg",
          title_ar: "معجزة في الزنزانة 7",
          description_ar:
            "بعد التفريق بينه وبين ابنته ، يُقاسي 'ميمو' الذي يعاني من إعاقة ذهنية من أجل إثبات براءته من جريمة لم يرتكبها.",
        },
        {
          poster:
            "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/0lv9e927v71d_LANDSCAPE_THUMBNAIL.jpg",
          title: "Myn Bala Warriors Of The Steppe",
          description:
            "Sartai the child who eyewitnessed the killing of his parents by the Mongols, grows up to seek revenge not only for his parents but also to liberate his homeland.",
          poster_ar:
            "https://d31ib6xnmsvhmh.cloudfront.net/POSTER/zw88jo6lstd_LANDSCAPE_THUMBNAIL.jpg",
          title_ar: "من بالا محاربو السهوب",
          description_ar:
            "سارتاي' الطفل الذي قتل المغول أبويه أمام عينيه، يكبر ليطلب ثأر شعبه ويصنع ملاحم كبيرة لتحرير وطنه'",
        },
      ],
      howToSubcribe: [
        {
          poster: require("@/assets/icons/pick_your_package.svg"),
          title: this.$t("1. Choose your package"),
        },
        {
          poster: require("@/assets/icons/Oman_mobile_icon.svg"),
          title: this.$t("2. Insert Your Mobile Number"),
        },
        {
          poster: require("@/assets/icons/recieve_otp.svg"),
          title: this.$t("3. Recieve Confirmation with OTP code"),
        },
        {
          poster: require("@/assets/icons/otp_code.svg"),
          title: this.$t("4. Use your OTP code"),
        },
        {
          poster: require("@/assets/icons/sms_confirmation.svg"),
          title: this.$t("5. Recieve an SMS confirmation"),
        },
        {
          poster: require("@/assets/icons/enjoying_watching.svg"),
          title: this.$t("6. Enjoy watching UNLIMITED ENTERTAINMENT"),
        },
      ],
      planList: [],
      gateways: [],
      loader: true,
      selectedGateway: null,
      componentModule: null,
      slider: null,
      counter: 1,
    };
  },
  computed: {
    ...mapGetters(["getToken", "appConfig"]),
    checkPartner() {
      if (
        this.$router.currentRoute.params.id === "stc-bahrain" ||
        this.$router.currentRoute.params.id === "ooredoo-oman" ||
 this.$router.currentRoute.params.id === "ooredoo-palestine" ||
        this.$router.currentRoute.params.id === "ooredoo-qatar" ||
        this.$router.currentRoute.params.id == "vodafone-egypt" ||
        this.$router.currentRoute.params.id == "we-egypt" ||
        this.$router.currentRoute.params.id == "orange-egypt" ||
        this.$router.currentRoute.params.id == "etisalat-egypt" ||
        this.$router.currentRoute.params.id === "etisalat-uae" ||
        this.$router.currentRoute.params.id === "zain-ksa" ||
        this.$router.currentRoute.params.id === "zain-jordan" ||
				this.$router.currentRoute.params.id === "zain-iraq" ||
        this.$router.currentRoute.params.id === "mobily-ksa" ||
         this.$router.currentRoute.params.id == "vodafone-qatar"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    gateways(val) {
      // if (val && val.length > 0) {
      //   let planIds = this.filterPlanIds(this.$router.currentRoute.params.id);
      //   console.log("planids", planIds);
      //   if (planIds && planIds.length > 0) {
      //     this.fetchPlans(planIds);
      //   } else {
      //     this.loader = false;
      //   }
      // }
    },
    localDisplayLang(val) {
      if (val) {
        if (this.slider) {
          if (this.localDisplayLang === "ara") {
            this.slider.goTo("last");
          } else {
            this.slider.goTo("first");
          }
        }
      }
    },
  },
  created() {
     //alert("tttteste")
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.fetchGatewaysFromConfig();

    let data = {
      telco_partner:
        this.$route.params && this.$route.params.id
          ? this.$route.params.id
          : "",
    };
    this.telco_landing_event(data);
  },
  mounted() {
    this.setLangingPageAnalytics();

    eventBus.$on("planlist-subscriptions-response", (response) => {
      console.log("plan list response from landing page", response);
      if (!response.reason) {
        this.planList = response.data;
        this.loadComponent(this.$router.currentRoute.params.id);
      } else {
        let data = {
          partner_name:
            this.$route.params && this.$route.params.id
              ? this.$route.params.id
              : "",
          error_type: "Plan_Listing",
          error_code: response.errorcode,
          error_message: response.reason,
        };
        this.telco_error_event(data);
      }
      this.loader = false;
    });

    if (screen.width < 576) {
      this.constructCarousel();
    }

    eventBus.$on("check-local-storage", (data) => {
      this.localDisplayLang = data;
    });

    console.log(
      "THE params FROM LANDING ---",
      this.$router.currentRoute.params.id,
      this.$router.currentRoute.params.provider
    );
  },
  methods: {
    ...mapActions(["listExternalGateways", "getClientIpAddress"]),

    setLangingPageAnalytics() {
      this.getClientIpAddress().then((data) => {
        const analyticsData = {
          ip: data.ip,
          url: document.URL,
        };

        this.telco_landing_page_tracker(data);
      });
    },

    fetchGatewaysFromConfig() {
      this.gateways = this.appConfig.paymentGateways;

      let planIds = this.filterPlanIds(this.$router.currentRoute.params.id);
      console.log("planids", planIds);
      if (planIds && planIds.length > 0) {
        this.fetchPlansConfig(planIds);
      } else {
        this.loader = false;
      }
    },

    fetchPlansConfig(planIds = []) {
      let listOfPlanConfig = this.appConfig.plansList;

      let arr = [];
      let payloadData = planIds.forEach((planId) => {
        for (let plan in listOfPlanConfig) {
          if (listOfPlanConfig[plan].planid == planId) {
            arr.push(listOfPlanConfig[plan]);
          }
        }
      });

      this.planList = arr;
      console.log("this.planList", this.planList);
      this.loadComponent(this.$router.currentRoute.params.id);
      this.loader = false;
    },

    fetchGateways() {
      let payload = { paypalsub: "YES" };
      this.listExternalGateways(payload)
        .then((response) => {
          console.log("gateway response", response);
          if (!response.reason) {
            this.gateways = response.data;
          } else {
            let data = {
              partner_name:
                this.$route.params && this.$route.params.id
                  ? this.$route.params.id
                  : "",
              error_type: "Gateway_Listing",
              error_code: response.errorcode,
              error_message: response.reason,
            };
            this.telco_error_event(data);

            if (response.data.errocode == 6066) {
              this.actNewDeviceRegister().then(() => {
                if (this.counter == 1) {
                  this.counter++;
                  this.fetchGateways();
                }
              });
            }
          }
        })
        .catch((error) => console.log((error) => console.log(error)));
    },

    filterPlanIds(id) {
      // Ooredoo - Qatar
      if (id == "ooredoo-qatar") {
        return this.fetchGateway("TELOOREDOOQT");
      }
      // Ooredoo - Oman
      else if (id == "ooredoo-oman") {
        return this.fetchGateway("TELOOREDOOOM");
      }
		else if (id == "ooredoo-palestine") {
        return this.fetchGateway("TELCOREDOOPS");
      }
      else if (id == "mobily-ksa") {
        return this.fetchGateway("TELCOMOBILY");
      }
      // STC - Bahrain
      else if (id == "stc-bahrain") {
        return this.fetchGateway("TELCOSTCBH");
      }
      // DU - UAE
      else if (id == "du-uae") {
        return this.fetchGateway("TIMWEDUUAE");
      }
      // Zain - Kuwait
      else if (id == "zain-kuwait") {
        return this.fetchGateway("TELCOZAINKWT");
      } else if (id == "zain-jordan") {
        return this.fetchGateway("TELCOZAINJOR");
      } else if (id == "etisalat-uae") {
        return this.fetchGateway("ETISALETUAE");
      } else if (id == "zain-ksa") {
        return this.fetchGateway("TELCOZAINKSA");
      } else if (id == "zain-iraq") {
        return this.fetchGateway("TELCOZAINIRQ");
      } else if (id == "vodafone-qatar") {
        return this.fetchGateway("TELCOVODAFONEQATAR");
      }
      // Vodafone - Egypt
      else if (id == "vodafone-egypt") {
        return this.fetchGateway("TPAYVODAFONE");
      }
      // Orange - Egypt
      else if (id == "orange-egypt") {
        return this.fetchGateway("TPAYORANGE");
      }
      // WE - Egypt
      else if (id == "we-egypt") {
        return this.fetchGateway("TPAYWE");
      }
      // Etisalat - Egypt
      else if (id == "etisalat-egypt") {
        return this.fetchGateway("TPAYETISALAT");
      }
    },

    fetchGateway(gatewayid) {
      this.selectedGateway =
        this.gateways[
          this.gateways.findIndex((element) => {
            return element.gatewayid == gatewayid;
          })
        ];
      return this.gateways[
        this.gateways.findIndex((element) => {
          return element.gatewayid == gatewayid;
        })
      ].ApplicaplePlanList;
    },

    fetchPlans(plans) {
      let payload = {};
      let planlist = [];

      plans.forEach((el) => {
        planlist.push(`"${el}"`);
      });

      payload.planvisibility = "YES";

      payload.planlist = `[${planlist}]`;

      payload.displaylanguage = this.localDisplayLang;

      eventBus.$emit("planList_subscriptions", payload);
    },

    getPoster() {
      // Ooredoo - oman
      if (this.$router.currentRoute.params.id == "ooredoo-oman") {
        // return require("@/assets/icons/Oman_logo.png");
        // Oman_logo_ramadan_ar-portrait
        if(this.localDisplayLang === "ara") {
          return require("@/assets/icons/Oman_logo.png");
        } else {
          return require("@/assets/icons/Oman_logo.png");
        }

        // if (screen.width < 576) {
        //   if (this.localDisplayLang === "ara") {
        //     return require("@/assets/icons/Oman_logo_ramadan_ar-portrait.png");
        //   } else {
        //     return require("@/assets/icons/Oman_logo_ramadan_en-portrait.png");
        //   }
        // } else {
        //   if (this.localDisplayLang === "ara") {
        //     return require("@/assets/icons/Oman_logo_ramadan_ar.png");
        //   } else {
        //     return require("@/assets/icons/Oman_logo_ramadan_en.png");
        //   }
        // }
      }
      // Oredoo - qatar
      else if (this.$router.currentRoute.params.id == "ooredoo-qatar") {
        return require("@/assets/icons/Qatar_logo.png");
      }
//palestine
 else if (this.$router.currentRoute.params.id == "ooredoo-palestine") {
			if (this.localDisplayLang === "ara") {
				return require("@/assets/icons/palestine-ar.png");
			}else {
					return require("@/assets/icons/palestine-en.png");
			}
      }
      // Stc - Bahrin
      else if (this.$router.currentRoute.params.id == "stc-bahrain") {
        return require("@/assets/icons/Bharain_logo.png");
      }
      // Etisalat UAE / DU-UAE
      else if (
        this.$router.currentRoute.params.id == "du-uae" ||
        this.$router.currentRoute.params.id == "etisalat-uae"
      ) {
        if (screen.width < 1100) {
          if (this.localDisplayLang === "ara") {
            return require("@/assets/icons/UAE-landscape-arabic-portrait.png");
          } else {
            return require("@/assets/icons/UAE-landscape-english-portrait.png");
          }
        } else if (this.localDisplayLang === "ara") {
          return require("@/assets/icons/UAE-landscape-arabic.png");
        } else {
          return require("@/assets/icons/UAE-landscape-english.png");
        }
      }
      // Zain Kuwait
      else if (this.$router.currentRoute.params.id == "zain-kuwait") {
        if (screen.width < 576) {
          if (this.localDisplayLang === "ara") {
            return require("@/assets/icons/Kuwait-Portrait-arabic.jpg");
          } else {
            return require("@/assets/icons/Kuwait-Portrait-english.jpg");
          }
        } else {
          if (this.localDisplayLang === "ara") {
            return require("@/assets/icons/Kuwait-landscape-arabic.jpg");
          } else {
            return require("@/assets/icons/Kuwait-landscape-english.jpg");
          }
        }
      } else if (
        this.$router.currentRoute.params.id == "vodafone-egypt" ||
        this.$router.currentRoute.params.id == "orange-egypt" ||
        this.$router.currentRoute.params.id == "we-egypt" ||
        this.$router.currentRoute.params.id == "etisalat-egypt"
      ) {
        if (this.localDisplayLang === "ara") {
          return require("@/assets/icons/tpay-landing-page-ar.png");
        } else {
          return require("@/assets/icons/tpay-landing-page-en.png");
        }
      }
      // Zain Jordan
      else if (this.$router.currentRoute.params.id == "zain-jordan") {
        if (screen.width < 1100) {
          if (this.localDisplayLang === "ara") {
            return require("@/assets/icons/Zain-Jordan-Ar-portrait.png");
          } else {
            return require("@/assets/icons/Zain-Jordan-En-portrait.png");
          }
        } else if (this.localDisplayLang === "ara") {
          return require("@/assets/icons/Zain-Jordan-Ar.png");
        } else {
          return require("@/assets/icons/Zain-Jordan-EN.png");
        }
      }
      // Zain KSA
      else if (this.$router.currentRoute.params.id == "zain-ksa" || this.$router.currentRoute.params.id == "mobily-ksa") {
        if (this.localDisplayLang === "ara") {
          return require("@/assets/icons/Zain-KSA-Ar.png");
        } else {
          return require("@/assets/icons/Zain-KSA-En.png");
        }
      } else if (this.$router.currentRoute.params.id == "zain-iraq") {
        if (this.localDisplayLang === "ara") {
          return require("@/assets/icons/zain-iraq-ar.png");
        } else {
          return require("@/assets/icons/zain-iraq-en.png");
        }
      } else if (this.$router.currentRoute.params.id == "vodafone-qatar") {
        if (this.localDisplayLang === "ara") {
          return require("@/assets/icons/Zain-KSA-Ar.jpg");
        } else {
          return require("@/assets/icons/Zain-KSA-Ar.jpg");
        }
      }
    },

    getSpecialPoster(content) {
      if (content.poster) {
        let posters = content.poster.filter((el) => {
          return el.postertype == "LANDSCAPE";
        });
        console.log("posters", posters);
        let index = posters[0].filelist.findIndex((el) => {
          return el.quality == "THUMBNAIL";
        });

        if (index > -1) {
          return posters[0].filelist[index].filename;
        }
      }
    },

    constructCarousel() {
      if (this.specialStories && this.specialStories.length > 0) {
        let data = {
          container: `#slider_carousel`,
          controlsContainer: `#slider_controls`,
          slideBy: 1,
          autoplay: true,
          autoplayHoverPause: true,
          autoplayButtonOutput: false,
          preventScrollOnTouch: "auto",
          autoHeight: true,
          freezable: false,
          loop: false,
          rewind: false,
          nav: false,
          speed: 400,
          items: 1,
          gutter: 100,
          edgePadding: 25,
        };
        this.$nextTick(() => {
          this.slider = tns(data);

          if (this.localDisplayLang === "ara") {
            this.slider.goTo("last");
          } else {
            this.slider.goTo("first");
          }
        });
      }
    },

    redirectFromLanding() {
      this.$emit("redirectFromLanding");
    },

    subscribeNow() {
      window.scrollTo(0, 0);
      let data = {
        telco_partner:
          this.$route.params && this.$route.params.id
            ? this.$route.params.id
            : "",
      };
      this.telco_subscribe_now(data);
    },

    actNewDeviceRegister() {
      return new Promise((resolve, reject) => {
        // localStorage.removeItem("random");
        encryptDeviceInfo()
          .then((response) => {
            store.dispatch("deviceRegister", response).then((response) => {
              store.commit("setToken", response.success);
              localStorage.setItem("deviceToken", response.success);
              resolve(true);
            });
          })
          .catch(() => {
            reject(false);
          });
      });
    },

    loadComponent(id) {
      if (
        id == "ooredoo-qatar" ||
        id == "ooredoo-oman" ||
				id == "ooredoo-palestine" ||
        id == "mobily-ksa" ||
        id == "du-uae" ||
        id == "vodafone-egypt" ||
        id == "orange-egypt" ||
        id == "we-egypt" ||
        id == "etisalat-egypt" ||
        id == "zain-ksa" ||
				id == "zain-iraq" ||
        id == "etisalat-uae" ||
        id == "vodafone-qatar" 
      ) {
        this.componentModule = () =>
          import(
            /* webpackChunkName: "planCardOTP" */ "@/components/Telco/telcoPlancardOtp.vue"
          );
      } else if (
        id == "stc-bahrain" ||
        id == "zain-kuwait" ||
        id == "zain-jordan"
      ) {
        this.componentModule = () =>
          import(
            /* webpackChunkName: "planCard" */ "@/components/Telco/telcoPlanCard.vue"
          );
      }
    },
  },
  components: {
    Loading: () =>
      import(
        /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"
      ),
    imageItem: () =>
      import(
        /* Webpack ChunkName: "imageItem" */ "@/components/Templates/imageItem.vue"
      ),
  },
  mixins: [googleAnalytics],
  beforeDestroy() {
    eventBus.$off("landing_page_contents");
    eventBus.$off("planlist-subscriptions-response");
    eventBus.$off("check-local-storage");

    if (this.slider) {
      this.slider.destroy();
    }
  },
};
</script>

<style lang="scss">
@import "node_modules/tiny-slider/src/tiny-slider.scss";
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.main_container {
  width: 75%;
  margin: 0 auto;
}

.landing_page_container {
  .gradient {
    background: rgb(23, 23, 23);
    background: linear-gradient(
      0deg,
      rgba(23, 23, 23, 1) 65%,
      rgba(57, 57, 57, 1) 100%
    );
  }
  &_planlist_container {
    position: relative;
    padding: 30px 0;
    // padding-top: 50px;
    // padding-bottom: 100px;
    &_card {
      &_loader {
        width: 100%;
      }
      &_no_plans {
        text-align: center;
        font-family: $font-helvetica-medium;
        color: rgba(255, 255, 255, 1);
      }
      &_item {
        width: 75vw;
        margin: 0 auto;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        &_poster_container {
          flex: auto;
          padding: 15px;
          img {
            height: auto;
            width: 100%;
          }
        }
      }
    }
  }
  &_suits_everyone {
    padding: 40px 0;
    &_title {
      margin-bottom: 40px;
      text-align: center;
      font-size: 40px;
      font-family: $font-helvetica-medium;
      color: rgba(255, 255, 255, 1);
    }
    &_item_container {
      display: flex;
      justify-content: space-between;
      &_item {
        text-align: center;
        img {
          width: 121px;
          height: 149px;
          margin-bottom: 15px;
        }
        p {
          font-family: $font-helvetica-medium;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
        }
      }
    }
  }
  &_special_stories {
    padding: 40px 0;
    &_title {
      text-align: center;
      font-size: 40px;
      background-color: #131313;
      padding: 40px;
      font-family: $font-helvetica-medium;
      color: rgba(255, 255, 255, 1);
    }
    &_item_container {
      &_slider_controls {
        display: none;
      }
      #slider_carousel {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        // grid-gap: 20px;
        text-align: center;
      }
      &_item {
        padding: 20px;
        &_postercard {
          display: flex;
          justify-content: center;
          position: relative;
          &_play {
            position: absolute;
            top: 35%;
            img {
              width: 55px;
              height: 55px;
            }
          }
          &_item {
            // width: 250px;
            // height: 140px;
            height: 100%;
            width: 100%;
            img {
              height: 100%;
              width: 100%;
              border-radius: 10px;
            }
          }
        }
        &_title {
          color: #e01866;
          font-family: $font-helvetica-medium;
          font-size: 20px;
          margin: 10px 0;
          text-align: center;
        }
        &_description {
          font-family: $font-helvetica-medium;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          line-height: 24px;
          text-align: justify;
        }
      }
    }
  }
  &_how_to_subscribe {
    background-color: #252525;
    &_container {
      // width: 60%;
      padding: 40px;
      margin: 0 auto;
      &_title {
        margin-bottom: 55px;
        text-align: center;
        font-size: 40px;
        font-family: $font-helvetica-medium;
        color: rgba(255, 255, 255, 1);
      }
      &_item_container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 35px;
        text-align: center;
        &_item {
          img {
            // width: 77px;
            // height: 133px;

            width: 144px;
            height: 105px;
            margin-bottom: 15px;
          }
          p {
            color: rgba(255, 255, 255, 1);
            font-family: $font-helvetica-medium;
            font-size: 18px;
          }
        }
      }
    }
  }
  &_subscribenow {
    text-align: center;
    padding: 25px 0;
    background-color: #252525;
    button {
      border: none;
      background: rgba(224, 24, 102, 1) 0% 0% no-repeat padding-box;
      border-radius: 10px;
      padding: 10px 30px;
      color: #ffffff;
      font-size: 20.72px;
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 951px) and (max-width: 1200px) {
  .main_container {
    width: 100%;
  }
  // .landing_page_container {
  //   &_planlist_container {
  //     padding: 20px;
  //     &_card {
  //       &_item {
  //         flex-direction: column-reverse;
  //       }
  //     }
  //   }
  // }
}

@media only screen and (min-width: 576px) and (max-width: 950px) {
  .main_container {
    width: 100%;
  }
  .landing_page_container {
    &_planlist_container {
      padding: 20px;
      &_card {
        &_item {
          flex-direction: column-reverse;
          &_plan_container {
            margin-top: -30px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .main_container {
    width: 100%;
  }
  .landing_page_container {
    .gradient {
      background: rgb(23, 23, 23);
      background: linear-gradient(
        0deg,
        rgba(23, 23, 23, 1) 35%,
        rgba(57, 57, 57, 1) 100%
      );
    }
    &_planlist_container {
      padding: 20px;
      &_card {
        &_item {
          // width: 95vw;
          flex-direction: column-reverse;
          &_plan_container {
            margin-top: -40px;
          }
          &_poster_container {
            padding: 10px;
            margin-bottom: 20px;
            .image_class_regular {
              height: 200px;
              width: 100%;
            }
            .image_class_additional {
              height: 400px;
              width: 100%;
            }
          }
        }
      }
    }
    &_suits_everyone {
      &_title {
        font-size: 22px;
      }
      &_item_container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        text-align: center;
        grid-gap: 25px;
        &_item {
          img {
            width: 100px;
            height: 56px;
          }
          p {
            font-size: 14px;
          }
          &:last-child {
            grid-column: 1 / span 2;
          }
        }
      }
    }
    &_special_stories {
      &_title {
        font-size: 22px;
      }
      &_item_container {
        display: block;
        position: relative;
        padding: 10px;
        &_slider_controls {
          display: block;
          position: absolute;
          z-index: 1001;
          width: 100%;
          top: 30%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          button {
            border: none;
            background: transparent;
            cursor: pointer;
            z-index: 1;
            transition: 0.3s;
            img {
              height: 50px;
              width: 50px;
            }
            &:disabled {
              opacity: 0.3;
            }
            &:focus {
              outline: none;
            }
          }
          &:focus {
            outline: none;
          }

          #slider_carousel-ow {
            display: none;
          }
        }
        #slider_carousel {
          display: block;
        }
        &_item {
          &_title {
            font-size: 16px;
          }
          &_description {
            font-size: 14px;
          }
        }
      }
    }
    &_how_to_subscribe {
      width: 100%;
      padding: 20px;
      &_container {
        width: 100%;
        padding: 20px 0;
        &_title {
          font-size: 22px;
        }
        &_item_container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 25px;
          text-align: center;
          &_item {
            img {
              width: 100px;
              height: 56px;
            }
            p {
              font-size: 14px;
            }
          }
        }
      }
    }
    &_subscribenow {
      text-align: center;
      padding: 25px 0;
      button {
        border: none;
        background: rgba(224, 24, 102, 1) 0% 0% no-repeat padding-box;
        border-radius: 5px;
        padding: 10px 30px;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
}
</style>
